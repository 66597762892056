import { Link } from "gatsby"
import React from "react";
import "../style/bootstrap.min.css";
import "../style/style.css";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";

function Header() {
  const data = useStaticQuery(graphql`
    {
      siteLogo: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "PacificFencing1.png" }
      ) {
        childImageSharp {
          fixed(width: 119, height: 66) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  
  return(
    <div className="Navbar">
    <div className="container">
    <Navbar collapseOnSelect expand="lg">
    <Navbar.Brand href="/"><Img
          fixed={data.siteLogo.childImageSharp.fixed}
          alt="logo"
        /></Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="ml-auto">
      <Nav.Link href="/">Home</Nav.Link>
      <Nav.Link href="/us/">About</Nav.Link>
      <NavDropdown title="Product & Services" id="basic-nav-dropdown">
      <NavDropdown.Item href="/products-services/">Our Services</NavDropdown.Item>
        <NavDropdown.Item href="/frameless-glass-pool-fencing-sydney/">Frameless Glass Pool Fencing</NavDropdown.Item>
        <NavDropdown.Item href="/sydney-semi-frameless-fencing/">Semi frameless fencing</NavDropdown.Item>
        <NavDropdown.Item href="/sydney-glass-balustrade/">Glass Balustrades</NavDropdown.Item>
        <NavDropdown.Item href="/sydney-glass-stairs/">Glass Stairs</NavDropdown.Item>
        <NavDropdown.Item href="/glass-gates/">Glass Gates</NavDropdown.Item>
        <NavDropdown.Item href="/glass-repair-sydney/">Glass Repair</NavDropdown.Item>
      </NavDropdown>
      <NavDropdown title="Warranty And Resources" id="basic-nav-dropdown">
        <NavDropdown.Item href="/warranty-performance-of-our/">Warranty</NavDropdown.Item>
        <NavDropdown.Item href="/resources/">Resources</NavDropdown.Item>
      </NavDropdown>
      <NavDropdown title="Contact And Testimonial" id="basic-nav-dropdown">
      <NavDropdown.Item href="/contact-us/">Contact</NavDropdown.Item>
        <NavDropdown.Item href="/testimonials/">Testimonials</NavDropdown.Item>
      </NavDropdown>    
    </Nav>
    <Button className="HeaderButton" href="tel:0290993940">Call: 02 9099 3940</Button>
  </Navbar.Collapse>
</Navbar>
</div>
</div>
  )
}

export default Header;
