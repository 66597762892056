import { Link } from "gatsby"
import React from "react";
import "../style/bootstrap.min.css";
import "../style/style.css";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby"

function Testimonial(){
   const data = useStaticQuery(graphql`
    {
        brendabaile: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "brenda-baile.png" }
      ) {
        childImageSharp {
          fixed(width: 90, height: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      kelsolohr: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "Kelso-Lohr.png" }
      ) {
        childImageSharp {
          fixed(width: 90, height: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      caseylucht: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "CaseyLucht.png" }
      ) {
        childImageSharp {
          fixed(width: 90, height: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }

    }
  `)

    return(
        <div className="container-fluid">
            <div class="row testimonial text-center">
            <div class="col-md-4">
                <div className="container-fluid testimonial-box shadow">
                    <Img fixed={data.brendabaile.childImageSharp.fixed} alt="brenda-baile" />
                    <p className="testimonial-title">We are delighted with the fencing work that Pacific Glass Pool Fencing team did recently. They worked hard to complete the job in one day...</p>
                    <p className="testimonial-name">Brenda Baile</p>
                </div>
            </div>
            <div class="col-md-4">
            <div className="container-fluid testimonial-box shadow">
            <Img fixed={data.kelsolohr.childImageSharp.fixed} alt="brenda-baile" />
            <p className="testimonial-title">Did a good job, on time and very fair price. I already booked him for the next month task. I would highly recommend him.</p>
            <p className="testimonial-name">Kelso Lohr</p>
                </div>
            </div>
            <div class="col-md-4">
            <div className="container-fluid testimonial-box shadow">
            <Img fixed={data.caseylucht.childImageSharp.fixed} alt="brenda-baile" />
            <p className="testimonial-title">They did good work in helping determine precisely what required. We needed a towering fence they figured out, and installation was perfect.</p>
            <p className="testimonial-name">Casey Lucht</p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Testimonial;