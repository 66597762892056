import { Link } from "gatsby"
import React from "react";
import "../style/bootstrap.min.css";
import "../style/style.css";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby"

function Footer(){
    const data = useStaticQuery(graphql`
    {
      footerLogo: file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "pf-icon.png" }
      ) {
        childImageSharp {
          fixed(width: 193, height: 193) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

    return(
        <div className="container-fluid">
        <div class="row footer">
  <div class="col-md-3"><Img
          fixed={data.footerLogo.childImageSharp.fixed}
          alt="footer_logo"
        /></div>
  <div class="col-md-3">
      <p className="FooterTitle">About Us</p>
      <p className="FooterDescription">Pacific Glass Pool Fencing has a staff of glass glazers that have been chosen to the the job because of their wealth of experience in industry. We only employ members who are highly qualified for the job.</p></div>
  <div class="col-md-3">
  <p className="FooterTitle">Get Around</p>
  <ul className="FooterDescription">
      <li><a href="/">Home</a></li>
      <li><a href="/us/">About us</a></li>
      <li><a href="/areas-service/">Areas We Service</a></li>
      <li><a href="/blog/">Blog</a></li>
      <li><a href="/contact-us/">Contact us</a></li>
  </ul>
  </div>
  <div class="col-md-3">
  <p className="FooterTitle">Our Products & Services</p>
  <ul className="FooterDescription">
      <li><a href="/frameless-glass-pool-fencing-sydney/">Frameless Glass Pool Fencing</a></li>
      <li><a href="/sydney-semi-frameless-fencing/">Semi Frameless Fencing</a></li>
      <li><a href="/sydney-glass-balustrade/">Custom Designed Glass Balustrades</a></li>
      <li><a href="/sydney-glass-stairs/">Glass Stairs Installation</a></li>
      <li><a href="/glass-gates/">Glass Gates</a></li>
      <li><a href="/glass-repair-sydney/">Glass Repair</a></li>
  </ul>
  </div>
</div>
</div>
    )
}

export default Footer;