import { Link } from "gatsby"
import React from "react";
import "../style/bootstrap.min.css";
import "../style/style.css";
import Button from 'react-bootstrap/Button';

function StickyButton(){

    return(
        <div className="sticky-button-element">
            <Button className="sticky-button text-center" href="tel:0290993940">Call: 02 9099 3940</Button>
        </div>
    )
}

export default StickyButton;